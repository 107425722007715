<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
      :title="$t('page.options.title')"
      @edit="$emit('edit')"
    >
    </page-title>
    <div
      v-if="model && !hasIncorporationAccess"
      class="box content"
    >
      <h4>{{ $t('page.options.physicalBook.title') }}</h4>
      <p
        v-if="isViewModeCreate"
        class="has-text-justified"
      >{{ $t('page.options.physicalBook.description') }}</p>
      <p
        v-if="isViewModeCreate"
        class="has-text-justified"
      >{{ $t('page.options.physicalBook.description-2') }}</p>
      <b-field :label="$t('form.field.physicalbook.label')">
        <div v-if="isFieldEditable('$.options.physicalbook')">
          <b-radio
            v-model="model.options.physicalbook"
            :native-value="false"
            name="physicalbook"
          >
            {{ $t('form.field.physicalbook.no') }}
          </b-radio>
          <b-radio
            v-model="model.options.physicalbook"
            :native-value="true"
            name="physicalbook"
          >
            {{ $t('form.field.physicalbook.yes') }}
          </b-radio>
        </div>
        <span v-else>{{ $t('form.field.physicalbook.' + (model.options.physicalbook ? 'yes' : 'no')) }}</span>
      </b-field>
      <b-button
        v-if="isViewModeCreate && model.options.physicalbook"
        class="mb-3"
        type="is-text is-anchor"
        @click="useHeadOfficeAddress"
      >
        {{ $t('page.options.physicalBook.useHeadOfficeAddress') }}
      </b-button>
      <section-address
        v-if="model.options.physicalbook"
        ref="physicalBookAddress"
        parent-path="$.options.physicalbookinfo"
        quebec-only
        :model="model.options.physicalbookinfo"
      >
      </section-address>
    </div>
    <div
      v-if="model"
      class="box content"
    >
      <h4>{{ $t('page.options.numbers.title') }}</h4>
      <p
        v-if="isViewModeCreate"
        class="has-text-justified"
      >{{ $t('page.options.taxes.description') }}</p>
      <p
        v-if="isViewModeCreate"
        class="has-text-justified"
      >{{ $t('page.options.das.description') }}</p>
      <div v-if="isViewModeCreate">
        <b-field :label="getFieldLabel('numbers')">
          <b-radio
            v-model="model.options.numbers"
            native-value="none"
            name="numbers"
          >
            {{ $t('form.field.numbers.none') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-radio
            v-model="model.options.numbers"
            native-value="taxes"
            name="numbers"
          >
            {{ $t('form.field.numbers.taxes') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-radio
            v-model="model.options.numbers"
            native-value="das"
            name="numbers"
          >
            {{ $t('form.field.numbers.das') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-radio
            v-model="model.options.numbers"
            native-value="both"
            name="numbers"
          >
            {{ $t('form.field.numbers.both') }}
          </b-radio>
        </b-field>
      </div>
      <b-field
        v-else
        :label="$t('form.field.numbers.label')"
      >
        <span>{{ $t('form.field.numbers.' + model.options.numbers) }}</span>
      </b-field>
      <div
        v-if="model.options.numbers !== 'none'"
        class="mt-5"
      >
        <div class="columns is-multiline">
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('headofficephone')"
              :type="getFieldType('headofficephone')"
              :message="getFieldMessage('headofficephone')"
            >
              <b-input
                v-if="isFieldEditable('$.options.info.headofficephone')"
                v-model="model.options.info.headofficephone"
                type="tel"
                @blur="validateHeadOfficePhone"
                name="phone"
                autocomplete="tel"
              >
              </b-input>
              <span v-else>{{ model.options.info.headofficephone }}</span>
            </b-field>
            <revision-message path="$.options.info.headofficephone"></revision-message>
          </div>
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('headofficefax')"
              :type="getFieldType('headofficefax')"
              :message="getFieldMessage('headofficefax')"
            >
              <b-input
                v-if="isFieldEditable('$.options.info.headofficefax')"
                v-model="model.options.info.headofficefax"
                type="tel"
                @blur="validateHeadOfficeFax"
                name="phone"
                autocomplete="tel"
              >
              </b-input>
              <span v-else>{{ model.options.info.headofficefax }}</span>
            </b-field>
            <revision-message path="$.options.info.headofficefax"></revision-message>
          </div>
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('headofficeemail')"
              :type="getFieldType('headofficeemail')"
              :message="getFieldMessage('headofficeemail')"
            >
              <b-input
                v-if="isFieldEditable('$.options.info.headofficeemail')"
                v-model="model.options.info.headofficeemail"
                type="email"
                @blur="validateHeadOfficeEmail"
                name="email"
                autocomplete="email"
              >
              </b-input>
              <span v-else>{{ model.options.info.headofficeemail }}</span>
            </b-field>
            <revision-message path="$.options.info.headofficeemail"></revision-message>
          </div>
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('estimatedrevenue')"
              :type="getFieldType('estimatedrevenue')"
              :message="getFieldMessage('estimatedrevenue')"
            >
              <b-input
                v-if="isFieldEditable('$.options.info.estimatedrevenue')"
                v-model="model.options.info.estimatedrevenue"
                @blur="validateEstimatedRevenue"
                autocomplete="off"
              >
              </b-input>
              <span v-else>{{ model.options.info.estimatedrevenue }}</span>
            </b-field>
            <revision-message path="$.options.info.estimatedrevenue"></revision-message>
          </div>
          <div class="column is-half">
            <b-field :label="getFieldLabel('taxesdate')">
              <div v-if="isFieldEditable('$.options.info.asap')">
                <b-radio
                  v-model="model.options.info.asap"
                  :native-value="true"
                  name="taxesdate"
                >
                  {{ $t('form.field.taxesdate.asap') }}
                </b-radio>
                <b-radio
                  v-model="model.options.info.asap"
                  :native-value="false"
                  name="taxesdate"
                >
                  {{ $t('form.field.taxesdate.specificDate') }}
                </b-radio>
              </div>
              <span v-else-if="model.options.info.asap">{{ $t('form.field.taxesdate.asap') }}</span>
              <span v-else-if="!isFieldEditable('$.options.info.taxesdate')">
                {{ formatDateWithSlashes(model.options.info.taxesdate) }}
              </span>
            </b-field>
            <b-field
              v-if="!model.options.info.asap && isFieldEditable('$.options.info.taxesdate')"
              :type="getFieldType('taxesdate')"
              :message="getFieldMessage('taxesdate')"
            >
              <b-datepicker
                v-model="model.options.info.taxesdate"
                :date-formatter="formatDateWithSlashes"
                :min-date="minTomorrowDate"
                icon="calendar-day"
                trap-focus
              >
              </b-datepicker>
            </b-field>
            <revision-message path="$.options.info.taxesdate"></revision-message>
          </div>
          <div class="column is-half">
            <b-field :label="getFieldLabel('declarationperiod')">
              <div v-if="isFieldEditable('$.options.info.declarationperiod')">
                <b-radio
                  v-model="model.options.info.declarationperiod"
                  native-value="monthly"
                  name="declarationperiod"
                >
                  {{ $t('form.field.declarationperiod.monthly') }}
                </b-radio>
                <b-radio
                  v-model="model.options.info.declarationperiod"
                  native-value="quaterly"
                  name="declarationperiod"
                >
                  {{ $t('form.field.declarationperiod.quaterly') }}
                </b-radio>
                <b-radio
                  v-model="model.options.info.declarationperiod"
                  native-value="annually"
                  name="declarationperiod"
                >
                  {{ $t('form.field.declarationperiod.annually') }}
                </b-radio>
              </div>
              <span v-else>{{ $t('form.field.declarationperiod.' + model.options.info.declarationperiod) }}</span>
            </b-field>
            <revision-message path="$.options.info.declarationperiod"></revision-message>
          </div>
        </div>
        <div
          v-if="model.options.numbers === 'das' || model.options.numbers === 'both'"
          class="columns is-multiline"
        >
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('salaryfirstdate')"
              :type="getFieldType('salaryfirstdate')"
              :message="getFieldMessage('salaryfirstdate')"
            >
              <b-datepicker
                v-if="isFieldEditable('$.options.info.salaryfirstdate')"
                v-model="model.options.info.salaryfirstdate"
                :min-date="minTomorrowDate"
                :date-formatter="formatDateWithSlashes"
                icon="calendar-day"
                trap-focus
                @blur="validateSalaryFirstDate"
              >
              </b-datepicker>
              <span v-else>{{ formatDateWithSlashes(model.options.info.salaryfirstdate) }}</span>
            </b-field>
            <revision-message path="$.options.info.salaryfirstdate"></revision-message>
          </div>
          <div class="column is-half">
            <b-field :label="getFieldLabel('salaryfrequency')">
              <b-select
                v-if="isFieldEditable('$.options.info.salaryfrequency')"
                v-model="model.options.info.salaryfrequency"
                expanded
              >
                <option
                  v-for="choice in $t('form.field.salaryfrequency.choices').slice(0)"
                  :value="choice.value"
                  :key="choice.value"
                >
                  {{ choice.label }}
                </option>
              </b-select>
              <span v-else>
                {{
                  $t('form.field.salaryfrequency.choices').find(choice => choice.value === model.options.info.salaryfrequency).label
                }}
              </span>
            </b-field>
            <revision-message path="$.options.info.salaryfrequency"></revision-message>
          </div>
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('salarytotal')"
              :type="getFieldType('salarytotal')"
              :message="getFieldMessage('salarytotal')"
            >
              <b-input
                v-if="isFieldEditable('$.options.info.salarytotal')"
                v-model="model.options.info.salarytotal"
                @blur="validateSalaryTotal"
                autocomplete="off"
              >
              </b-input>
              <span v-else>{{ model.options.info.salarytotal }}</span>
            </b-field>
            <revision-message path="$.options.info.salarytotal"></revision-message>
          </div>
          <div class="column is-half">
            <b-field :label="$t('form.field.employees.label')">
              <b-select
                v-if="isFieldEditable('$.options.info.employeescount')"
                v-model="model.options.info.employeescount"
                expanded
              >
                <option
                  v-for="choice in $t('form.field.employees.choices').slice(0)"
                  :value="choice.value"
                  :key="choice.value"
                >
                  {{ choice.label }}
                </option>
              </b-select>
              <span v-else>
                {{
                  $t('form.field.employees.choices').find(choice => choice.value === model.options.info.employeescount).label
                }}
              </span>
            </b-field>
            <revision-message path="$.options.info.employeescount"></revision-message>
          </div>
          <div class="column is-half">
            <b-field :label="getFieldLabel('employeestype')">
              <div v-if="isFieldEditable('$.options.info.employeestype')">
                <b-radio
                  v-model="model.options.info.employeestype"
                  native-value="annual"
                  name="employeestype"
                >
                  {{ $t('form.field.employeestype.annual') }}
                </b-radio>
                <b-radio
                  v-model="model.options.info.employeestype"
                  native-value="seasonal"
                  name="employeestype"
                >
                  {{ $t('form.field.employeestype.seasonal') }}
                </b-radio>
              </div>
              <span v-else>{{ $t('form.field.employeestype.' + model.options.info.employeestype) }}</span>
            </b-field>
            <revision-message path="$.options.info.employeestype"></revision-message>
          </div>
          <div class="column is-half">
            <b-field :label="getFieldLabel('estimateddeductions')">
              <div v-if="isFieldEditable('$.options.info.employeestype')">
                <b-radio
                  v-model="model.options.info.estimateddeductions"
                  native-value="lessthan1000"
                  name="estimateddeductions"
                >
                  {{ $t('form.field.estimateddeductions.lessthan1000') }}
                </b-radio>
                <b-radio
                  v-model="model.options.info.estimateddeductions"
                  native-value="morethan1000"
                  name="estimateddeductions"
                >
                  {{ $t('form.field.estimateddeductions.morethan1000') }}
                </b-radio>
              </div>
              <span v-else>{{ $t('form.field.estimateddeductions.' + model.options.info.estimateddeductions) }}</span>
            </b-field>
            <revision-message path="$.options.info.estimateddeductions"></revision-message>
          </div>
          <div
            v-for="(director, index) in model.director"
            :key="index"
            class="column is-half"
          >
            <div class="box">
              <h5>{{ `${director.firstname} ${director.lastname}` }}</h5>
              <b-field
                :label="getFieldLabel('ssn')"
                :type="getFieldType('ssn', index)"
                :message="getFieldMessage('ssn', index)"
              >
                <b-input
                  v-if="isFieldEditable(`$.director[${index}].ssn`)"
                  v-model="director.ssn"
                  @blur="validateSSN(index)"
                  autocomplete="off"
                >
                </b-input>
                <span v-else>{{ director.ssn }}</span>
              </b-field>
              <revision-message :path="`$.director[${index}].ssn`"></revision-message>
              <b-field
                :label="getFieldLabel('assessmentnumber')"
                :type="getFieldType('assessmentnumber', index)"
                :message="getFieldMessage('assessmentnumber', index)"
              >
                <b-input
                  v-if="isFieldEditable(`$.director[${index}].assessmentnumber`)"
                  v-model="director.assessmentnumber"
                  @blur="validateAssessmentNumber(index)"
                  autocomplete="off"
                >
                </b-input>
                <span v-else>{{ director.assessmentnumber }}</span>
              </b-field>
              <revision-message :path="`$.director[${index}].assessmentnumber`"></revision-message>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import page from '@/mixins/page';
import date from '@/mixins/date';
import SectionAddress from './SectionAddress';
import cloneDeep from 'lodash-es/cloneDeep';

export default {
  name: 'PageOptions',
  mixins: [
    page(['options', 'director']),
    date
  ],
  components: {
    SectionAddress
  },
  methods: {
    useHeadOfficeAddress () {
      document.activeElement?.blur();
      const addressFields = ['address', 'city', 'province', 'postalcode'];
      addressFields.forEach(key => {
        this.$set(this.model.options.physicalbookinfo, key, this.storedModel.headOffice[key]);
      });
      this.validate();
    },
    updateModelPartially () {
      const storedModelCopy = cloneDeep(this.storedModel['director']);
      this.model.director.forEach((director, index) => {
        ['ssn', 'assessmentnumber'].forEach((prop) => {
          storedModelCopy[index][prop] = director[prop];
        });
      });
      this.model.director = storedModelCopy;
    },
    validate () {
      this.clearErrors();

      if (this.model.options.physicalbook) {
        const addressErrors = this.$refs.physicalBookAddress.validate();
        if (addressErrors?.length)
          this.addError('physicalBookAddress');
        else
          this.removeError('physicalBookAddress');
      }

      if (this.model.options.numbers !== 'none') {
        this.validateEstimatedRevenue();
        this.validateHeadOfficeEmail();
        this.validateHeadOfficeFax();
        this.validateHeadOfficePhone();
        this.validateTaxesDate();

        this.model.director.forEach((director, index) => {
          this.validateAssessmentNumber(index);
          this.validateSSN(index);
        });

        if (['das', 'both'].includes(this.model.options.numbers)) {
          this.validateSalaryFirstDate();
          this.validateSalaryFrequency();
          this.validateSalaryTotal();
        }
      }
    },
    validateAssessmentNumber (index) {
      this.validateNotEmpty(`assessmentnumber${index}`, this.model.director[index].assessmentnumber);
    },
    validateEstimatedRevenue () {
      this.validateNotEmpty('estimatedrevenue', this.model.options.info.estimatedrevenue);
    },
    validateHeadOfficeEmail () {
      this.validateRegex('headofficeemail', this.model.options.info.headofficeemail, /^\S+@\S+$/);
    },
    validateHeadOfficeFax () {
      if (this.model.options.info.headofficefax.length)
        this.validateMinLength('headofficefax', this.model.options.info.headofficefax, 10);
      else
        this.removeError('headofficefax');
    },
    validateHeadOfficePhone () {
      this.validateMinLength('headofficephone', this.model.options.info.headofficephone, 10);
    },
    validateSSN (index) {
      this.validateRegex(`ssn${index}`, this.model.director[index].ssn, /^\d{9}$/);
    },
    validateSalaryFirstDate () {
      if (!this.model.options.info.salaryfirstdate)
        this.addError('salaryfirstdate');
      else
        this.removeError('salaryfirstdate');
    },
    validateSalaryFrequency () {
      this.validateNotEmpty('salaryfrequency', this.model.options.info.salaryfrequency);
    },
    validateSalaryTotal () {
      this.validateNotEmpty('salarytotal', this.model.options.info.salarytotal);
    },
    validateTaxesDate () {
      if (!this.model.options.info.asap && !this.model.options.info.taxesdate)
        this.addError('taxesdate');
      else
        this.removeError('taxesdate');
    }
  }
};
</script>
