<template>
  <div class="columns is-multiline">
    <div class="column is-full">
      <b-field
        :label="getFieldLabel('address')"
        :type="getFieldType('address')"
        :message="getFieldMessage('address')"
      >
        <b-input
          v-if="isFieldEditable(`${this.parentPath}.address`)"
          v-model="model.address"
          @blur="validateAddress"
          :name="`address${index}`"
          autocomplete="street-address"
        >
        </b-input>
        <span v-else>{{ model.address }}</span>
      </b-field>
      <revision-message :path="`${this.parentPath}.address`"></revision-message>
    </div>
    <div class="column is-one-third">
      <b-field
        :label="getFieldLabel('city')"
        :type="getFieldType('city')"
        :message="getFieldMessage('city')"
      >
        <b-input
          v-if="isFieldEditable(`${this.parentPath}.city`)"
          v-model="model.city"
          @blur="validateCity"
          :name="`city${index}`"
          autocomplete="address-level2"
        >
        </b-input>
        <span v-else>{{ model.city }}</span>
      </b-field>
      <revision-message :path="`${this.parentPath}.city`"></revision-message>
    </div>
    <div class="column is-one-third">
      <b-field
        :label="getFieldLabel('province')"
        :type="getFieldType('province')"
        :message="getFieldMessage('province')"
      >
        <b-select
          v-if="isFieldEditable(`${this.parentPath}.province`)"
          v-model="model.province"
          expanded
          @input="validateProvince"
          :name="`province${index}`"
          autocomplete="address-level1"
        >
          <option
            v-for="provinceCode in provinces"
            :key="provinceCode"
            :value="provinceCode"
          >
            {{ $t('province.' + provinceCode) }}
          </option>
        </b-select>
        <span v-else>{{ $t('province.' + model.province) }}</span>
      </b-field>
      <revision-message :path="`${this.parentPath}.province`"></revision-message>
    </div>
    <div class="column is-one-third">
      <b-field
        :label="getFieldLabel('postalcode')"
        :type="getFieldType('postalcode')"
        :message="getFieldMessage('postalcode')"
      >
        <b-input
          v-if="isFieldEditable(`${this.parentPath}.postalcode`)"
          v-model="model.postalcode"
          @blur="validatePostalcode"
          :name="`postal${index}`"
          autocomplete="postal-code"
        >
        </b-input>
        <span v-else>{{ model.postalcode }}</span>
      </b-field>
      <revision-message :path="`${this.parentPath}.postalcode`"></revision-message>
    </div>
  </div>
</template>

<script>
import validator from '@/mixins/validator';

export default {
  name: 'SectionAddress',
  mixins: [validator(true)],
  props: {
    model: Object,
    index: {
      type: Number,
      default: () => 0
    },
    parentPath: String,
    quebecOnly: Boolean
  },
  computed: {
    provinces () {
      return Object.keys(this.$t('province'))
        .filter(p => this.quebecOnly ? 'QC' === p : true);
    }
  },
  methods: {
    validate () {
      this.validateAddress();
      this.validateCity();
      this.validatePostalcode();
      this.validateProvince();

      return this.errors;
    },
    validateAddress () {
      this.validateNotEmpty('address', this.model.address);
    },
    validateCity () {
      this.validateNotEmpty('city', this.model.city);
    },
    validatePostalcode () {
      this.validateRegex('postalcode', this.model.postalcode, /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    },
    validateProvince () {
      this.validateNotEmpty('province', this.model.province);
    }
  }
};
</script>
