export default {
  data () {
    const atLeastEighteenYearsOld = this.$moment().subtract(18, 'years').toDate()
    const minTomorrowDate = this.$moment().add(1, 'days').toDate()

    return {
      atLeastEighteenYearsOld,
      minTomorrowDate
    }
  },
  methods: {
    addWeekdays (date, days) {
      date = this.$moment(date)
      while (days > 0) {
        date = date.add(1, 'days')
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
          days -= 1
        }
      }
      return date
    },
    formatBirthdate (birthdate) {
      return this.$moment(birthdate).locale(this.$i18n.locale).format(this.$i18n.locale === 'en' ? 'MMMM Do YYYY' : 'Do MMMM YYYY')
    },
    formatDateWithSlashes (date) {
      return this.$moment(date).format('DD/MM/YYYY')
    }
  }
}
